import { configureStore } from '@reduxjs/toolkit'
import sidebarReducer from './features/sidebar/sidebarSlice'
import userReducer from './features/users/userSlice'
import ItemReducer from './features/all_Items/itemSlice'
import brandReducer from './features/brand/brandSlice'
import entryReducer from './features/entry/entrySlice'
import productReducer from './features/products/productSlice'
import paymentSlice from './features/payments/paymentSlice'
import reportSlice from './features/reports/reportSlice'
import filterSlice from './features/filter/filterSlice'
import challanSlice from './features/filter/challanSlice'
import performaSlice from './features/filter/performaSlice'
import upcommingSlice from './features/filter/upcommingSlice'
import printedSlice from './features/filter/printedSlice'
import pendingSlice from './features/filter/pendingSlice'
import dispatchSlice from './features/filter/dispatchSlice'
import paymentFilterSlice from './features/filter/paymentSlice'
import ledgerSlice from './features/filter/ledgerSlice'
import modelSlice from './features/filter/modelSlice'
import mockupSlice from './features/filter/mockupSlice'
import productSlice from './features/filter/productSlice'
import breadcrumbSlice from './features/filter/breadcrumbSlice'
import orderGenSlice from './features/order_gen/orderGenSlice'
import reminderSlice from './features/filter/reminderSlice'

export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    user: userReducer,
    items: ItemReducer,
    brands: brandReducer,
    entries: entryReducer,
    products: productReducer,
    payment: paymentSlice,
    reports: reportSlice,
    filter: filterSlice,
    challanfilter: challanSlice,
    performafilter: performaSlice,
    upcommingfilter: upcommingSlice,
    printedfilter: printedSlice,
    pendingfilter: pendingSlice,
    dispatchfilter: dispatchSlice,
    paymentfilter: paymentFilterSlice,
    ledgerfilter: ledgerSlice,
    modelfilter: modelSlice,
    mockupfilter: mockupSlice,
    productfilter: productSlice,
    breadcrumb: breadcrumbSlice,
    orderGen: orderGenSlice,
    reminder: reminderSlice,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: false,
  //   }),
})
