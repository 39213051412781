import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  loading: false,
  orderGenData: {},
  error: null,
}

const orderGenSlice = createSlice({
  name: 'ordersgens',
  initialState,
  reducers: {
    orderGenerate: (state, action) => {
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder

      // Fetch all List data
      .addCase(fetchOrderGenData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchOrderGenData.fulfilled, (state, { payload: { result, error } }) => {
        state.loading = false
        if (result) {
          state.orderGenData = result
          state.error = null
        } else {
          state.orderGenData = null
          state.error = error
        }
      })
      .addCase(fetchOrderGenData.rejected, (state, action) => {
        state.loading = false
        state.orderGenData = null
        state.error = 'Something went wrong with the server'
      })
  },
})

export const fetchOrderGenData = createAsyncThunk('fetch/order_gen', async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/order_gen`, data, {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
      timeout: 900000, // 15 minutes in milliseconds
    })

    return response.data
  } catch (err) {
    console.log(err, 'err')
    if (err.response) {
      // Server responded with a status other than 200 range
      return { error: err.response.data.error.map((e) => `${e.path} has ${e.msg}`).join('.\n') }
    } else if (err.request) {
      // Request was made but no response received
      return { error: 'No response from server. Request timed out or server is unreachable.' }
    } else {
      // Something happened in setting up the request
      return { error: err.message }
    }
  }
})

export default orderGenSlice.reducer
