import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {}

const reminderSlice = createSlice({
  name: 'reminder',
  initialState,
  reducers: {
    addItem: (state, action) => {
        // console.log("action.payload", action.payload)
        return action.payload;
    },
  },
})

export const { addItem } = reminderSlice.actions
export default reminderSlice.reducer
